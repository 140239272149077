import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import RichText from '~components/RichText';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end'
  },
  body: {
    padding: '0px 60px 80px 60px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 21px 80px 21px'
    }
  },
  buttonStyle: {
    right: '20px',
    top: '20px',
    borderRadius: '50%',
    backgroundColor: '#282828',
    zIndex: 1
  },
  iconStyle: {
    fontSize: '18px',
    color: '#FFFFFF'
  },
  name: {
    fontSize: '26px',
    fontFamily: 'Inter',
    color: '#FFFFFF',
    lineHeight: '32px',
    fontWeight: 800,
    marginBlockStart: '8px',
    marginBlockEnd: '0px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
      lineHeight: '26px'
    }
  },
  position: {
    fontSize: '18px',
    fontFamily: 'Inter',
    color: '#FFFFFF',
    lineHeight: '23px',
    marginBlockStart: '2px',
    marginBlockEnd: '16px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
      lineHeight: '21px'
    }
  },
  avatar: {
    borderRadius: 999,
    maxWidth: '114px',
    maxHeight: '114px',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '78px',
      maxHeight: '78px'
    }
  },
  description: {
    marginTop: '27px',
    '& > *': {
      color: '#FFFFFF',
      margin: 0,
      fontFamily: 'Inter',
      fontSize: '16px',
      lineHeight: '24px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '14px',
        lineHeight: '21px'
      }
    },
    '& > p:empty': {
      height: '24px',
      [theme.breakpoints.down('xs')]: {
        height: '21px'
      }
    }
  }
}));

const DescriptionDialog = ({ name, position, avatar, description, onClose, show }) => {
  const classes = useStyles();

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={show}
      maxWidth="md"
      PaperProps={{
        style: { borderRadius: '30px', backgroundColor: '#282828' }
      }}
    >
      <div className={classes.header}>
        <IconButton aria-label="close" className={classes.buttonStyle} onClick={onClose}>
          <CloseIcon className={classes.iconStyle} />
        </IconButton>
      </div>
      <div className={classes.body}>
        <img src={avatar && avatar.url} alt={avatar && avatar.alt} className={classes.avatar} />
        <Typography className={classes.name}>{name}</Typography>
        <Typography className={classes.position}>{position}</Typography>
        <RichText
          html={description && description.html}
          externalClassName={classes.description}
          verticalSpacing={0}
        />
      </div>
    </Dialog>
  );
};

DescriptionDialog.propTypes = {
  name: PropTypes.string.isRequired,
  avatar: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string
  }).isRequired,
  position: PropTypes.string.isRequired,
  description: PropTypes.shape({
    html: PropTypes.string
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};

export default DescriptionDialog;
