import React, { useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';

import AuthorAvatar from './author-avatar';
import DescriptionDialog from './DescriptionDialog';

const useStyles = makeStyles({
  name: {
    color: '#FFFFFF',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '140%',
    marginTop: 0,
    marginBottom: 0,
    textAlign: 'left'
  },
  designation: {
    color: '#B9B9BD',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '140%',
    marginTop: 0,
    marginBottom: 0
  },
  contentArea: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 18,
    cursor: 'pointer'
  }
});

const Author = ({ avatar, name, role, biography }) => {
  const classes = useStyles();

  const [showBio, setShowBio] = useState(false);
  const onReadMoreClick = () => {
    setShowBio(true);
  };
  const onCloseDescriptionDialog = () => setShowBio(false);

  return (
    <>
      {showBio && (
        <DescriptionDialog
          show
          onClose={onCloseDescriptionDialog}
          avatar={avatar}
          name={name}
          position={role}
          description={biography}
        />
      )}
      <div
        className={classes.contentArea}
        tabIndex={0}
        role="button"
        onClick={onReadMoreClick}
        onKeyDown={event => {
          if (['keydown', 'keypress'].includes(event.type) && ['Enter', ' '].includes(event.key)) {
            onReadMoreClick();
          }
        }}
      >
        <AuthorAvatar {...avatar} />
        <div>
          <h4 className={classes.name}>{name}</h4>
          <p className={classes.designation}>{role}</p>
        </div>
      </div>
    </>
  );
};

Author.propTypes = {
  avatar: PropTypes.shape({
    alt: PropTypes.string,
    url: PropTypes.string.isRequired
  }).isRequired,
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  biography: PropTypes.shape({
    html: PropTypes.string
  })
};

Author.defaultProps = {
  biography: null
};

export default Author;
