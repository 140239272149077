import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import RichText from '~components/RichText';
import Author from '../Author';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end'
  },
  body: {
    maxWidth: '900px',
    padding: '0px 60px 80px 60px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 40px 60px 20px'
    }
  },
  buttonStyle: {
    right: '20px',
    top: '20px',
    borderRadius: '50%',
    backgroundColor: '#282A30',
    zIndex: 1,
    padding: 4,
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: '#282828'
    },
    [theme.breakpoints.down('xs')]: {
      right: '10px',
      top: '10px'
    }
  },
  iconStyle: {
    fontSize: '18px',
    color: '#FFFFFF'
  },
  heading: {
    color: '#F1F1F1',
    fontFamily: 'Inter',
    fontSize: '35px',
    fontWeight: 700,
    lineHeight: '130%',
    marginTop: '21px',
    marginBottom: 0,
    textAlign: 'left',
    maxWidth: '550px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px'
    }
  },
  subheading: {
    color: '#F1F1F1',
    fontFamily: 'Inter',
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '130%',
    marginTop: '6px',
    marginBottom: 0,
    textAlign: 'left',
    maxWidth: '550px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px'
    }
  },
  content: {
    '& > *': {
      color: '#BABABE',
      fontFamily: 'Inter !important',
      fontSize: '16px !important',
      lineHeight: '150% !important',
      textAlign: 'left'
    },
    '& > div > *': {
      color: '#BABABE',
      fontFamily: 'Inter !important',
      fontSize: '16px !important',
      lineHeight: '150% !important',
      textAlign: 'left'
    }
  },
  authorContainer: {
    marginTop: '20px'
  }
}));

const DescriptionDialog = ({ onClose, show, title, videoTitle, description, author }) => {
  const classes = useStyles();

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="video-popup"
      open={show}
      maxWidth="md"
      PaperProps={{
        style: { borderRadius: '10px', width: '100%', backgroundColor: '#282A30' }
      }}
    >
      <div className={classes.header}>
        <IconButton aria-label="close" className={classes.buttonStyle} onClick={onClose}>
          <CloseIcon className={classes.iconStyle} />
        </IconButton>
      </div>
      <div className={classes.body}>
        <h1 className={classes.heading}>{title}</h1>
        <h2 className={classes.subheading}>{videoTitle}</h2>
        <RichText html={description} verticalSpacing={0} externalClassName={classes.content} />
        <div className={classes.authorContainer}>
          <Author {...author} />
        </div>
      </div>
    </Dialog>
  );
};

DescriptionDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  videoTitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  author: PropTypes.shape({ name: PropTypes.string }).isRequired
};

export default DescriptionDialog;
