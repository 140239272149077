import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Container from '~components/Container';

const Author = loadable(() => import('../Author'));
const DescriptionText = loadable(() => import('./DescriptionText'));

const useStyles = makeStyles(theme => ({
  descriptionArea: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: props => (props.compactMargins ? '16px' : '40px')
  },
  descriptionTexts: {
    maxWidth: '500px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: props => (props.compactMargins ? '5px' : '23px')
  },
  heading: {
    color: '#F1F1F1',
    fontFamily: 'Inter',
    fontSize: '35px',
    fontWeight: 700,
    lineHeight: '130%',
    marginTop: '30px',
    marginBottom: 0,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: '20px',
      fontSize: '28px'
    }
  },
  subheading: {
    color: '#F1F1F1',
    fontFamily: 'Inter',
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '130%',
    marginTop: '6px',
    marginBottom: 0,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px'
    }
  },
  ctaButton: {
    cursor: 'pointer',
    color: '#000000',
    backgroundColor: '#FFFFFF',
    padding: '19px 32px',
    fontSize: 18,
    lineHeight: '18px',
    fontWeight: 700,
    borderRadius: 45,
    textTransform: 'none',
    marginBottom: 20,
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: '#F1F1F1'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
      lineHeight: '16px',
      borderRadius: 36,
      padding: '18px 20px'
    }
  }
}));

const VideoInfoProminentPlayVariant = ({
  videoTitle,
  currentVideo,
  title,
  author,
  onDescriptionExpand,
  onPlayClick,
  compactMargins
}) => {
  const classes = useStyles({ compactMargins });

  return (
    <Container center>
      <div className={classes.descriptionArea}>
        <div className={classes.descriptionTexts}>
          <div>
            <h1 className={classes.heading}>{title}</h1>
            <h2 className={classes.subheading}>{videoTitle}</h2>
            <DescriptionText
              html={currentVideo.description.html}
              maxLines={7}
              onExpandClick={onDescriptionExpand}
            />
          </div>
          <Author {...author} />
        </div>

        <Button onClick={onPlayClick} className={classes.ctaButton}>
          Start Watching
        </Button>
      </div>
    </Container>
  );
};

VideoInfoProminentPlayVariant.propTypes = {
  videoTitle: PropTypes.string.isRequired,
  currentVideo: PropTypes.shape({
    transcript: PropTypes.shape({ html: PropTypes.string.isRequired }).isRequired,
    wistia_url: PropTypes.shape({ url: PropTypes.string }),
    trailer_wistia_url: PropTypes.shape({ url: PropTypes.string }),
    description: PropTypes.shape({ html: PropTypes.string.isRequired }).isRequired,
    uid: PropTypes.string.isRequired,
    prismicId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired,
  title: PropTypes.string.isRequired,
  author: PropTypes.shape({ name: PropTypes.string }).isRequired,
  onDescriptionExpand: PropTypes.func.isRequired,
  onPlayClick: PropTypes.func.isRequired,
  compactMargins: PropTypes.bool
};

VideoInfoProminentPlayVariant.defaultProps = {
  compactMargins: false
};

export default VideoInfoProminentPlayVariant;
