import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { IN_VIDEO_POPUPS } from '~layout/InVideoBlocker/InVideoBlocker';
import Popups from '~layout/Popups';
import { AB_EXPERIMENTS } from '~utils/experiment-helper';
import CustomAnimatedButton from '~src/components/CustomAnimatedButton';
import ClickableText from '~src/components/buttons/ClickableText';
import VideoInfo from './VideoInfo';
import VideoDialog from './VideoDialog';
import DescriptionDialog from './DescriptionDialog';
import VideoInfoProminentPlayVariant from './VideoInfoProminentPlayVariant';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: '#0f0f0f',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    maxHeight: '70vh',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      maxHeight: '100% !important'
    }
  },
  heroCover: {
    position: 'relative',
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    '& img': {
      objectFit: 'cover',
      width: '100%',
      height: '100%'
    }
  },
  overlay: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      width: '100%',
      height: '100%',
      position: 'absolute',
      bottom: '0',
      background: 'linear-gradient(to bottom, transparent, #0f0f0f 100%)'
    }
  },
  descriptionArea: {
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      marginTop: props => (props.compactMargins ? '-300px' : '-120px'),
      width: '100%'
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: props => (props.compactMargins ? '-160px' : '-90px')
    }
  },
  animatedButton: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    margin: '-41px 0 0 -41px',
    cursor: 'pointer'
  }
}));

const VideoHero = ({
  heroCoverImage,
  isPlaying,
  currentVideo,
  popup,
  isPopupOpen,
  title,
  author,
  prismicId,
  thumbnail,
  isSingleVideo,
  uid,
  labels,
  onReady,
  onStart,
  onProgress,
  onEnded,
  toggleVideoPlayState,
  currentVideoIndex,
  numberOfVideos,
  showGiftPopup,
  handleInsufficientAccess,
  handleBlockingPopupClose,
  compactMargins,
  variant,
  isFreeContent
}) => {
  const classes = useStyles({ compactMargins });
  const [showDescriptionDialog, setShowDescriptionDialog] = useState(false);
  const [showVideoDialog, setShowVideoDialog] = useState(false);

  const onDescriptionExpand = useCallback(() => {
    setShowDescriptionDialog(true);
    setShowVideoDialog(false);
  }, []);

  const onCloseDescriptionDialog = useCallback(() => {
    setShowDescriptionDialog(false);
  }, []);

  const onPlayClick = useCallback(() => {
    setShowVideoDialog(true);
    setShowDescriptionDialog(false);
  }, []);

  const onCloseVideoDialog = useCallback(() => {
    setShowVideoDialog(false);
    toggleVideoPlayState(false);
    handleBlockingPopupClose();
  }, [toggleVideoPlayState, handleBlockingPopupClose]);

  const videoTitle = useMemo(
    () =>
      numberOfVideos > 1
        ? `${currentVideo.title} (Part ${currentVideoIndex + 1} of ${numberOfVideos})`
        : currentVideo.title,
    [numberOfVideos, currentVideo.title, currentVideoIndex]
  );
  /**
   * A video blocker can be either inside the video or take the full screen
   * Here, let's check if the popup/blocker should be an in-video or not
   */
  const { inVideoPopup, showInVideoPopup } = useMemo(() => {
    const inVideoPopupIndex = IN_VIDEO_POPUPS.indexOf(popup);
    return {
      inVideoPopup: inVideoPopupIndex > -1 ? IN_VIDEO_POPUPS[inVideoPopupIndex] : undefined,
      showInVideoPopup: isPopupOpen && inVideoPopupIndex > -1
    };
  }, [popup, isPopupOpen]);

  return (
    <>
      {showVideoDialog && (
        <VideoDialog
          show
          isPlaying={isPlaying}
          url={currentVideo?.trailer_wistia_url?.url || currentVideo?.wistia_url?.url}
          onClose={onCloseVideoDialog}
          onReady={onReady}
          onStart={onStart}
          onProgress={onProgress}
          onEnded={onEnded}
          showInVideoPopup={showInVideoPopup}
          inVideoPopup={inVideoPopup}
        />
      )}
      {showDescriptionDialog && (
        <DescriptionDialog
          show
          onClose={onCloseDescriptionDialog}
          title={title}
          videoTitle={videoTitle}
          description={currentVideo.description.html}
          author={author}
        />
      )}
      {popup && !inVideoPopup && (
        <Popups
          which={popup}
          isPopupOpen={isPopupOpen}
          handleClose={handleBlockingPopupClose}
          playlistId={prismicId}
          playlistSlug={uid}
          thumbnail={thumbnail}
          playlistTitle={title}
          videoTitle={currentVideo.title}
          author={author.name}
          toggleVideoPlayState={toggleVideoPlayState}
        />
      )}
      <section className={classes.root}>
        <div className={classes.heroCover}>
          <img src={heroCoverImage?.url} alt={heroCoverImage?.alt} />
          <div className={classes.overlay} />
          {variant === AB_EXPERIMENTS.VIDEO_PLAYLIST.VARIANTS.PROMINENT_PLAY_BUTTON && (
            <ClickableText component="div" onClick={onPlayClick} className={classes.animatedButton}>
              <CustomAnimatedButton backgroundColor="rgba(0, 0, 0, 0.3)" iconColor="#FFFFFF" />
            </ClickableText>
          )}
        </div>
        <div className={classes.descriptionArea}>
          {variant === AB_EXPERIMENTS.VIDEO_PLAYLIST.VARIANTS.PROMINENT_PLAY_BUTTON ? (
            <VideoInfoProminentPlayVariant
              videoTitle={videoTitle}
              currentVideo={currentVideo}
              title={title}
              author={author}
              labels={labels}
              onDescriptionExpand={onDescriptionExpand}
              onPlayClick={onPlayClick}
              compactMargins={compactMargins}
            />
          ) : (
            <VideoInfo
              videoTitle={videoTitle}
              currentVideo={currentVideo}
              title={title}
              author={author}
              isSingleVideo={isSingleVideo}
              labels={labels}
              showGiftPopup={showGiftPopup}
              handleInsufficientAccess={handleInsufficientAccess}
              onDescriptionExpand={onDescriptionExpand}
              onPlayClick={onPlayClick}
              compactMargins={compactMargins}
              isFreeContent={isFreeContent}
            />
          )}
        </div>
      </section>
    </>
  );
};

VideoHero.propTypes = {
  heroCoverImage: PropTypes.shape({
    alt: PropTypes.string,
    url: PropTypes.string
  }).isRequired,
  isPlaying: PropTypes.bool.isRequired,
  currentVideo: PropTypes.shape({
    transcript: PropTypes.shape({ html: PropTypes.string.isRequired }).isRequired,
    trailer_wistia_url: PropTypes.shape({ url: PropTypes.string }),
    wistia_url: PropTypes.shape({ url: PropTypes.string }),
    description: PropTypes.shape({ html: PropTypes.string.isRequired }).isRequired,
    uid: PropTypes.string.isRequired,
    prismicId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired,

  onReady: PropTypes.func.isRequired,
  onStart: PropTypes.func.isRequired,
  onProgress: PropTypes.func.isRequired,
  onEnded: PropTypes.func.isRequired,
  toggleVideoPlayState: PropTypes.func.isRequired,
  popup: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  isPopupOpen: PropTypes.bool,
  title: PropTypes.string.isRequired,
  author: PropTypes.shape({ name: PropTypes.string }).isRequired,
  prismicId: PropTypes.string.isRequired,
  thumbnail: PropTypes.shape({ alt: PropTypes.string, url: PropTypes.string }).isRequired,
  isSingleVideo: PropTypes.bool.isRequired,
  uid: PropTypes.string.isRequired,
  labels: PropTypes.shape({
    isAudio: PropTypes.bool.isRequired,
    isNew: PropTypes.bool.isRequired,
    isPremium: PropTypes.bool.isRequired,
    isProducer: PropTypes.bool.isRequired
  }).isRequired,
  currentVideoIndex: PropTypes.number,
  numberOfVideos: PropTypes.number,
  showGiftPopup: PropTypes.func.isRequired,
  handleInsufficientAccess: PropTypes.func.isRequired,
  handleBlockingPopupClose: PropTypes.func.isRequired,
  compactMargins: PropTypes.bool,
  variant: PropTypes.oneOf(Object.values(AB_EXPERIMENTS.VIDEO_PLAYLIST.VARIANTS)),
  isFreeContent: PropTypes.bool
};

VideoHero.defaultProps = {
  isPopupOpen: false,
  popup: undefined,
  currentVideoIndex: 0,
  numberOfVideos: 1,
  compactMargins: false,
  variant: AB_EXPERIMENTS.VIDEO_PLAYLIST.VARIANTS.CONTROL,
  isFreeContent: false
};

export default VideoHero;
