import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { calculateVideoContentType } from '~utils/playlist-helpers';
import Container from '~components/Container';
import MarketingVideoActionBar from '~components/video-action-bar/MarketingVideoActionBar';
import RoundedLinkButton from '~components/buttons/RoundedLinkButton';
import { getSubscribeButtonProps } from '~utils/buttontext';
import { useTestId } from '~utils/set-testid';
import { useUserContext } from '~context/UserContext/UserSessionContext';
import useConversionFramework from '~hooks/useConversionFramework';

const Author = loadable(() => import('../Author'));
const DescriptionText = loadable(() => import('./DescriptionText'));

const useStyles = makeStyles(theme => ({
  descriptionArea: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: props => (props.compactMargins ? '16px' : '40px')
  },
  descriptionTexts: {
    maxWidth: '500px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: props => (props.compactMargins ? '5px' : '23px')
  },
  heading: {
    color: '#F1F1F1',
    fontFamily: 'Inter',
    fontSize: '35px',
    fontWeight: 700,
    lineHeight: '130%',
    marginTop: '30px',
    marginBottom: 0,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: '20px',
      fontSize: '28px'
    }
  },
  subheading: {
    color: '#F1F1F1',
    fontFamily: 'Inter',
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '130%',
    marginTop: '6px',
    marginBottom: 0,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px'
    }
  },
  actionArea: {
    width: '100%',
    marginTop: '60px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    rowGap: '30px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '40px'
    }
  },
  readMoreBtn: {
    color: '#FFFFFF',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    cursor: 'pointer'
  }
}));

const VideoInfo = ({
  videoTitle,
  currentVideo,
  title,
  author,
  isSingleVideo,
  labels,
  handleInsufficientAccess,
  showGiftPopup,
  onDescriptionExpand,
  onPlayClick,
  compactMargins,
  isFreeContent
}) => {
  const classes = useStyles({ compactMargins });
  const { isMarketingSite } = useConversionFramework();
  const { jwtAvailable, marketingUser } = useUserContext().session;
  const contentType = calculateVideoContentType(isSingleVideo, labels.isAudio);
  const { text, url, shouldTrackAnalytics, analyticsEvent } = getSubscribeButtonProps(
    jwtAvailable,
    isMarketingSite,
    marketingUser
  );
  const tryFreeTestId = useTestId('playlist-free-trial-button');

  return (
    <Container center>
      <div className={classes.descriptionArea}>
        <div className={classes.descriptionTexts}>
          <div>
            <h1 className={classes.heading}>{title}</h1>
            <h2 className={classes.subheading}>{videoTitle}</h2>
            <DescriptionText
              html={currentVideo.description.html}
              maxLines={7}
              onExpandClick={onDescriptionExpand}
            />
          </div>
          <Author {...author} />
        </div>

        <RoundedLinkButton
          text={text}
          to={url}
          shouldTrackAnalytics={shouldTrackAnalytics}
          analyticsEvent={analyticsEvent}
          analyticsTitle={title}
          analyticsSection="Playlist"
          size="small"
          variant="blue"
          {...tryFreeTestId}
        />

        <MarketingVideoActionBar
          title={title}
          videoTitle={currentVideo.title}
          contentType={contentType.toLowerCase()}
          showGiftPopup={showGiftPopup}
          videoUid={currentVideo.uid}
          hasUserSufficientAccess={false}
          handleInsufficientAccess={handleInsufficientAccess}
          onPlayActionClicked={onPlayClick}
          playAction
          isShort
          light
          isFreeContent={isFreeContent}
        />
      </div>
    </Container>
  );
};

VideoInfo.propTypes = {
  videoTitle: PropTypes.string.isRequired,
  currentVideo: PropTypes.shape({
    transcript: PropTypes.shape({ html: PropTypes.string.isRequired }).isRequired,
    wistia_url: PropTypes.shape({ url: PropTypes.string }),
    trailer_wistia_url: PropTypes.shape({ url: PropTypes.string }),
    description: PropTypes.shape({ html: PropTypes.string.isRequired }).isRequired,
    uid: PropTypes.string.isRequired,
    prismicId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired,
  showGiftPopup: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  author: PropTypes.shape({ name: PropTypes.string }).isRequired,
  isSingleVideo: PropTypes.bool.isRequired,
  labels: PropTypes.shape({
    isAudio: PropTypes.bool.isRequired,
    isNew: PropTypes.bool.isRequired,
    isPremium: PropTypes.bool.isRequired,
    isProducer: PropTypes.bool.isRequired
  }).isRequired,
  handleInsufficientAccess: PropTypes.func.isRequired,
  onDescriptionExpand: PropTypes.func.isRequired,
  onPlayClick: PropTypes.func.isRequired,
  compactMargins: PropTypes.bool,
  isFreeContent: PropTypes.bool
};

VideoInfo.defaultProps = {
  compactMargins: false,
  isFreeContent: false
};

export default VideoInfo;
